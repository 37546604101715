.cc-window {
  opacity: 1;
  transition: opacity 1s;
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-animate.cc-revoke {
  transition: transform 1s, -webkit-transform 1s;
}

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em);
}

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em);
}

.cc-animate.cc-revoke.cc-active.cc-top, .cc-animate.cc-revoke.cc-active.cc-bottom, .cc-revoke:hover {
  transform: translateY(0);
}

.cc-grower {
  max-height: 0;
  transition: max-height 1s;
  overflow: hidden;
}

.cc-revoke, .cc-window {
  box-sizing: border-box;
  z-index: 9999;
  flex-wrap: nowrap;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: flex;
  position: fixed;
  overflow: hidden;
}

.cc-window.cc-static {
  position: static;
}

.cc-window.cc-floating {
  max-width: 24em;
  flex-direction: column;
  padding: 2em;
}

.cc-window.cc-banner {
  width: 100%;
  flex-direction: row;
  padding: 1em 1.8em;
}

.cc-revoke {
  padding: .5em;
}

.cc-revoke:hover {
  text-decoration: underline;
}

.cc-header {
  font-size: 18px;
  font-weight: 700;
}

.cc-btn, .cc-close, .cc-link, .cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: .8;
  padding: .2em;
  text-decoration: underline;
  display: inline-block;
}

.cc-link:hover {
  opacity: 1;
}

.cc-link:active, .cc-link:visited {
  color: initial;
}

.cc-btn {
  text-align: center;
  white-space: nowrap;
  border-style: solid;
  border-width: 2px;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  display: block;
}

.cc-highlight .cc-btn:first-child {
  background-color: #0000;
  border-color: #0000;
}

.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: #0000;
  text-decoration: underline;
}

.cc-close {
  opacity: .9;
  font-size: 1.6em;
  line-height: .75;
  display: block;
  position: absolute;
  top: .5em;
  right: .5em;
}

.cc-close:focus, .cc-close:hover {
  opacity: 1;
}

.cc-revoke.cc-top {
  border-bottom-left-radius: .5em;
  border-bottom-right-radius: .5em;
  top: 0;
  left: 3em;
}

.cc-revoke.cc-bottom {
  border-top-left-radius: .5em;
  border-top-right-radius: .5em;
  bottom: 0;
  left: 3em;
}

.cc-revoke.cc-left {
  left: 3em;
  right: unset;
}

.cc-revoke.cc-right {
  right: 3em;
  left: unset;
}

.cc-top {
  top: 1em;
}

.cc-left {
  left: 1em;
}

.cc-right {
  right: 1em;
}

.cc-bottom {
  bottom: 1em;
}

.cc-floating > .cc-link {
  margin-bottom: 1em;
}

.cc-floating .cc-message {
  margin-bottom: 1em;
  display: block;
}

.cc-window.cc-floating .cc-compliance {
  flex: 1 0 auto;
}

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  top: 0;
  left: 0;
  right: 0;
}

.cc-banner.cc-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}

.cc-banner .cc-message {
  max-width: 100%;
  flex: auto;
  margin-right: 1em;
  display: block;
}

.cc-compliance {
  align-content: space-between;
  align-items: center;
  display: flex;
}

.cc-floating .cc-compliance > .cc-btn {
  flex: 1;
}

.cc-btn + .cc-btn {
  margin-left: .5em;
}

@media print {
  .cc-revoke, .cc-window {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0;
  }

  .cc-window.cc-bottom {
    bottom: 0;
  }

  .cc-window.cc-banner, .cc-window.cc-floating, .cc-window.cc-left, .cc-window.cc-right {
    left: 0;
    right: 0;
  }

  .cc-window.cc-banner {
    flex-direction: column;
  }

  .cc-window.cc-banner .cc-compliance {
    flex: auto;
  }

  .cc-window.cc-floating {
    max-width: none;
  }

  .cc-window .cc-message {
    margin-bottom: 1em;
  }

  .cc-window.cc-banner {
    -webkit-box-align: unset;
    -ms-flex-align: unset;
    align-items: unset;
  }

  .cc-window.cc-banner .cc-message {
    margin-right: 0;
  }
}

.cc-floating.cc-theme-classic {
  border-radius: 5px;
  padding: 1.2em;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  flex: none;
  display: inline;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}

.cc-theme-edgeless.cc-window {
  padding: 0;
}

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em;
}

.cc-banner.cc-theme-edgeless .cc-btn {
  height: 100%;
  margin: 0;
  padding: .8em 1.8em;
}

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em;
}

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0;
}

/*# sourceMappingURL=index.5367800c.css.map */
